import revive_payload_client_BHyTkiVL7a from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.47_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._fvjfvyr5pptjbumkl32nzpbhwq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_XiAm52X6aq from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.47_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._fvjfvyr5pptjbumkl32nzpbhwq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Qy1JVLzCxP from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.47_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._fvjfvyr5pptjbumkl32nzpbhwq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VUHxNGDzRh from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.47_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._fvjfvyr5pptjbumkl32nzpbhwq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_mgnhvRvlEz from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.47_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._fvjfvyr5pptjbumkl32nzpbhwq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_h3NdPjNVFH from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.47_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._fvjfvyr5pptjbumkl32nzpbhwq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_BwdfqbKMWJ from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.47_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._fvjfvyr5pptjbumkl32nzpbhwq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_gVVlEmJ5Ol from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.2_typescript@5.5.4_vue@3.4.38_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_lWuP119xVt from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.47_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._fvjfvyr5pptjbumkl32nzpbhwq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_CfmOMpgaj4 from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.5_rollup@4.21.2_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_BsbmcfFB3b from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.5_rollup@4.21.2_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_client_g3202afUA8 from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4_magicast@0.3.5_rollup@4.21.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_2gKIznN1wZ from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.11_magicast@0.3.5_rollup@4.21.2_typescript@5.5.4_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import floating_vue_EIcJ7xiw0h from "/opt/build/repo/.nuxt/floating-vue.mjs";
import pwa_icons_Oze9N4N6sN from "/opt/build/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.7.0_magicast@0.3.5_rollup@4.21.2_vite@5.4.2_@types+node@18.19.47_sass@1.77.8_msfu7uhyhpfmaqi6gt2en2c5vq/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_lT9JwOZGAM from "/opt/build/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.7.0_magicast@0.3.5_rollup@4.21.2_vite@5.4.2_@types+node@18.19.47_sass@1.77.8_msfu7uhyhpfmaqi6gt2en2c5vq/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_nuxt3_SRGlRv4DN6 from "/opt/build/repo/node_modules/.pnpm/nuxt-mail@5.0.1_magicast@0.3.5_rollup@4.21.2/node_modules/nuxt-mail/dist/plugin-nuxt3.js";
import plugin_vfJ9eD2HXz from "/opt/build/repo/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.2_typesc_cmanhvbhw7ypacpizobu3ygwsm/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import apollo_1ILFkzpYSr from "/opt/build/repo/plugins/apollo.ts";
import chartjs_1wC9lAM0Tp from "/opt/build/repo/plugins/chartjs.ts";
import hotjar_client_HJG8no80GI from "/opt/build/repo/plugins/hotjar.client.ts";
import maska_UHaKf2z1iQ from "/opt/build/repo/plugins/maska.ts";
import tippy_xFBYqfAPCu from "/opt/build/repo/plugins/tippy.ts";
export default [
  revive_payload_client_BHyTkiVL7a,
  unhead_XiAm52X6aq,
  router_Qy1JVLzCxP,
  payload_client_VUHxNGDzRh,
  navigation_repaint_client_mgnhvRvlEz,
  check_outdated_build_client_h3NdPjNVFH,
  chunk_reload_client_BwdfqbKMWJ,
  plugin_vue3_gVVlEmJ5Ol,
  components_plugin_KR1HBZs4kY,
  prefetch_client_lWuP119xVt,
  switch_locale_path_ssr_CfmOMpgaj4,
  i18n_BsbmcfFB3b,
  plugin_client_g3202afUA8,
  plugin_2gKIznN1wZ,
  floating_vue_EIcJ7xiw0h,
  pwa_icons_Oze9N4N6sN,
  pwa_client_lT9JwOZGAM,
  plugin_nuxt3_SRGlRv4DN6,
  plugin_vfJ9eD2HXz,
  apollo_1ILFkzpYSr,
  chartjs_1wC9lAM0Tp,
  hotjar_client_HJG8no80GI,
  maska_UHaKf2z1iQ,
  tippy_xFBYqfAPCu
]