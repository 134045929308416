import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css'
// import '@/assets/scss/components/tippy.scss'
import 'tippy.js/animations/shift-away.css'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(VueTippy, {
    directive: 'tippy',
    component: 'tippy',
    defaultProps: {
      theme: 'space',
      animation: 'shift-away',
    },
  })
})
