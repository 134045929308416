import { default as indexbun7E8qlGoMeta } from "/opt/build/repo/domains/app/pages/app/index/dashboard/index.vue?macro=true";
import { default as indexODh00QeDmYMeta } from "/opt/build/repo/domains/app/pages/app/index/settings/index.vue?macro=true";
import { default as GalaxyGeneratorb2FWbDP0EOMeta } from "/opt/build/repo/domains/app/pages/app/index/tools/GalaxyGenerator.vue?macro=true";
import { default as QrCodeGenerator2dET0tP3KcMeta } from "/opt/build/repo/domains/app/pages/app/index/tools/QrCodeGenerator.vue?macro=true";
import { default as indexw1JtqWhd8MMeta } from "/opt/build/repo/domains/app/pages/app/index.vue?macro=true";
import { default as loginJiXA6yUd8bMeta } from "/opt/build/repo/pages/auth/index/login.vue?macro=true";
import { default as _91token_93SF4HIjpWrgMeta } from "/opt/build/repo/pages/auth/index/resetPassword/[token].vue?macro=true";
import { default as indexcPHnKKMm3hMeta } from "/opt/build/repo/pages/auth/index/resetPassword/index.vue?macro=true";
import { default as _91token_93LZrxohTqEdMeta } from "/opt/build/repo/pages/auth/index/signup/[token].vue?macro=true";
import { default as indexPcXHZT2narMeta } from "/opt/build/repo/pages/auth/index/signup/index.vue?macro=true";
import { default as signup0H8tBLHKvEMeta } from "/opt/build/repo/pages/auth/index/signup.vue?macro=true";
import { default as indexzXuJ7S0F7fMeta } from "/opt/build/repo/pages/auth/index.vue?macro=true";
import { default as _91name_93GO7aznpN8GMeta } from "/opt/build/repo/domains/blog/pages/blog/[name].vue?macro=true";
import { default as indexsd6PzQZYbBMeta } from "/opt/build/repo/domains/blog/pages/blog/index.vue?macro=true";
import { default as CookievlZoXGfPNNMeta } from "/opt/build/repo/pages/Cookie.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as Privacy00Qwyv1pTxMeta } from "/opt/build/repo/pages/Privacy.vue?macro=true";
export default [
  {
    name: "app",
    path: "/app",
    meta: indexw1JtqWhd8MMeta || {},
    component: () => import("/opt/build/repo/domains/app/pages/app/index.vue").then(m => m.default || m),
    children: [
  {
    name: "app-index-dashboard",
    path: "dashboard",
    meta: indexbun7E8qlGoMeta || {},
    component: () => import("/opt/build/repo/domains/app/pages/app/index/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "app-index-settings",
    path: "settings",
    component: () => import("/opt/build/repo/domains/app/pages/app/index/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "app-index-tools-GalaxyGenerator",
    path: "tools/GalaxyGenerator",
    component: () => import("/opt/build/repo/domains/app/pages/app/index/tools/GalaxyGenerator.vue").then(m => m.default || m)
  },
  {
    name: "app-index-tools-QrCodeGenerator",
    path: "tools/QrCodeGenerator",
    component: () => import("/opt/build/repo/domains/app/pages/app/index/tools/QrCodeGenerator.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth",
    path: "/auth",
    meta: indexzXuJ7S0F7fMeta || {},
    component: () => import("/opt/build/repo/pages/auth/index.vue").then(m => m.default || m),
    children: [
  {
    name: "auth-index-login",
    path: "login",
    component: () => import("/opt/build/repo/pages/auth/index/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-index-resetPassword-token",
    path: "resetPassword/:token()",
    component: () => import("/opt/build/repo/pages/auth/index/resetPassword/[token].vue").then(m => m.default || m)
  },
  {
    name: "auth-index-resetPassword",
    path: "resetPassword",
    component: () => import("/opt/build/repo/pages/auth/index/resetPassword/index.vue").then(m => m.default || m)
  },
  {
    name: signup0H8tBLHKvEMeta?.name,
    path: "signup",
    meta: signup0H8tBLHKvEMeta || {},
    component: () => import("/opt/build/repo/pages/auth/index/signup.vue").then(m => m.default || m),
    children: [
  {
    name: "auth-index-signup-token",
    path: ":token()",
    meta: _91token_93LZrxohTqEdMeta || {},
    component: () => import("/opt/build/repo/pages/auth/index/signup/[token].vue").then(m => m.default || m)
  },
  {
    name: "auth-index-signup",
    path: "",
    component: () => import("/opt/build/repo/pages/auth/index/signup/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "blog-name",
    path: "/blog/:name()",
    meta: _91name_93GO7aznpN8GMeta || {},
    component: () => import("/opt/build/repo/domains/blog/pages/blog/[name].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexsd6PzQZYbBMeta || {},
    component: () => import("/opt/build/repo/domains/blog/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "Cookie",
    path: "/Cookie",
    component: () => import("/opt/build/repo/pages/Cookie.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "Privacy",
    path: "/Privacy",
    component: () => import("/opt/build/repo/pages/Privacy.vue").then(m => m.default || m)
  }
]