<template>
  <div>
    <NuxtLoadingIndicator
      color="#a542b8"
      :height="3"
      :throttle="400"
    />
    <NuxtLayout>
      <VitePwaManifest />
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
