<script setup lang="ts">
import type { RouteRecordRaw } from 'vue-router'

interface Props {
  type?: 'submit' | 'button' | 'reset'
  icon?: string
  loading?: boolean
  variant?: 'link' | 'solid' | 'outline' | 'soft' | 'ghost'
  target?: '_blank' | '_self' | '_parent' | '_top'
  to?: string | Partial<RouteRecordRaw>
  disabled?: boolean
  leadingIcon?: string
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  block?: boolean
  square?: boolean
  padded?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  color: 'primary',
  type: 'button',
  size: 'md',
  padded: true,
})

const buttonTag = computed(() => {
  return props.to ? resolveComponent('NuxtLink') : 'button'
})

const variantClass = computed(() => {
  return {
    link: '-:dark:text-secondary-700 -:text-blue-700',
    solid:
      '-:bg-blue-800 -:text-blue-50  -:dark:bg-secondary-800 -:dark:text-secondary-50',
    outline: 'border border-secondary-500 text-secondary-500 ',
    soft: 'dark:bg-secondary-950 dark:text-secondary-200 bg-blue-300 text-blue-800 ',
    ghost: 'text-secondary-300',
  }[props.variant || 'solid']
})

const sizeClass = computed(() => {
  return {
    xs: 'text-xs -:px-2 -:py-1',
    sm: 'text-sm -:px-3 -:py-2',
    md: 'text-base px-4 -:py-2',
    lg: 'text-lg -:px-6 -:py-3',
    xl: 'text-xl -:px-8 -:py-4',
  }[props.size || 'md']
})

const iconSize = computed(
  () =>
    ({
      xs: '12',
      sm: '14',
      md: '16',
      lg: '20',
      xl: '24',
    })[props.size || 'md'],
)

const padding = computed(() => {
  return props.padded ? '' : 'p-0'
})
</script>

<template>
  <component
    :is="buttonTag"
    :to="to"
    :type="type"
    :disabled="disabled"
    :target="target"
    class="inline-flex items-center justify-center rounded-3xl"
    :class="[variantClass, sizeClass, padding]"
  >
    <Icon
      v-if="loading"
      class="text-current shrink-0"
      :size="iconSize"
      :class="{ spin: loading }"
      name="i-svg-spinners-6-dots-rotate"
    />
    <Icon
      v-if="leadingIcon"
      class="text-current shrink-0"
      :size="iconSize"
      :name="leadingIcon"
    />

    <slot />
  </component>
</template>
